.nav {
  &__item {
    &--logo {
      &::after {
        content: '';
        height: 80px;
        width: 100%;
        position: absolute;
        top: 2.5rem;
        left: 3vw;
        background-image: url('../../../../public/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &--overview,
    &--listing {
      position: relative;

      a {
        display: inline-block;
        width: 100%;
        padding-left: 2.5rem;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        border-radius: 10px 0 0 10px;
        transition: background 0.15s ease;

        &:hover {
          background-color: $color_white;
          transition: background 0.15s ease;
        }
      }

      .active {
        background-color: $color_white;
        font-weight: 600;
        &::after {
          content: '';
          height: 54px;
          width: 8px;
          background-color: $color_primary;
          position: absolute;
          top: 0;
          left: -1.55rem;
          border-radius: 0 5px 5px 0;
        }
      }
    }

    &--overview {
      margin-bottom: 1rem;
      &::before {
        content: '';
        height: 15px;
        width: 15px;
        background-image: url('../../../../public/overview.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 20px;
        left: 14px;
      }
    }

    &--listing {
      &::before {
        content: '';
        height: 20px;
        width: 20px;
        background-image: url('../../../../public/listing.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 18.5px;
        left: 14px;
      }
    }
  }

  &__logout {
    position: absolute;
    bottom: 6vh;
    display: flex;
    flex-direction: column;

    &--support {
      margin-bottom: 1.5rem;
      position: relative;
      max-width: 100%;
      padding: 10px 10px 10px 2.5rem;
      transition: background 0.15s ease;
      border-radius: 50px;

      &::before {
        content: '';
        height: 15px;
        width: 15px;
        background-image: url('../../../../public/phone.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0.65rem;
        left: 10px;
      }

      &:hover {
        transition: background 0.15s ease;
        background-color: $color_white;
      }
    }

    &--btn {
      /* border: 1px solid $color_primary; */
      border-radius: 18px;
      background-color: transparent;
      color: $color_primary;
      padding: 10px 1rem 10px 2.5rem;
      transition: background 0.15s ease;

      &:hover {
        background-color: $color_white;
        transition: background 0.15s ease;
      }
      &:focus {
        background-color: $color_blue_light_op;
      }
    }

    &__text {
      position: relative;
      &::before {
        content: '';
        height: 17px;
        width: 17px;
        background-image: url('../../../../public/logout.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 2px;
        left: -30px;
      }
    }
  }

  &__container {
    margin-top: 15vh;
    margin-bottom: 20vh;
  }

  &__secondary {
    position: absolute;
    top: 2rem;
    right: 1rem;
    display: flex;
    width: fit-content;
    display: flex;
    align-items: flex-start;
  }
}

.profile {
  margin-top: 0.25rem;
  &:hover {
    cursor: pointer;
  }
}

.MuiFormControl-root {
  background: #f1f4f8 !important;
  border-radius: 5px !important;
  padding-left: 0.5rem !important;
}

.MuiInputBase-root {
  &::before {
    display: none;
  }
}

.MuiNativeSelect-select {
  font-family: $font_main !important;
  color: $color_primary !important;
}

body[data-menu] {
  .profile {
    display: none;
  }

  .logincard {
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }
}

@include screen-size(xxl) {
  .nav {
    &__logout {
      margin-left: -1vw;
    }
  }
}

@include screen-size(xl) {
  .nav {
    &__item {
      &--logo {
        &::after {
          left: 3.2vw;
        }
      }

      &--overview,
      &--listing {
        a {
          padding-right: 0.5rem;
        }
      }

      &--support {
        margin-left: 1px;
        &::before {
          top: 0.75rem;
          left: 25px;
        }
      }
    }

    &__logout {
      margin-left: 2.4vw;

      &--support,
      &--btn {
        color: transparent;
        width: 16px;
        height: 32px;
      }
    }
  }
}
