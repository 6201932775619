.tasks {
  &__header {
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $color_blue_light_op;

    h2 {
      font-size: 32px;
      line-height: 60px;
    }

    .link {
      margin-bottom: 1.2rem;
      margin-left: 1rem;

      &::after {
        top: 0.18rem;
      }
    }
  }

  &__filter {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    &--sub {
      width: 100%;
      display: flex;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
        cursor: not-allowed;
      }
    }
  }

  &__grid {
    margin-bottom: 15rem;
  }

  &__add {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 1rem;
      width: max-content;
    }

    * {
      transition: all 0.15s ease;
    }

    &:hover,
    &:focus {
      * {
        transition: all 0.15s ease;
        fill: $color_card_hover;
      }
    }

    &:active {
      * {
        fill: $color_primary;
      }
    }
  }

  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;

    &--step-2 {
      .form__field__title {
        background-color: transparent !important;
      }

      .MuiSelect-select .MuiSelect-filled,
      .MuiInputBase-root {
        background-color: white !important;

        input {
          background-color: white !important;
        }
      }
    }

    .listing__cell {
      &:hover {
        cursor: pointer;
      }
    }

    .tasks h1 {
      font-size: 32px;
    }

    &__grid {
      margin-top: 2rem;
    }

    &__search {
      width: 100% !important;
      margin-top: 2rem !important;
      position: relative;

      &-input {
        display: flex;
        align-items: flex-end;
        column-gap: 0.5rem;

        .tasks__modal__search-input-btn {
          margin-bottom: 0.5rem;
          margin-left: 0.5rem;

          span {
            &::after,
            &::before,
            svg {
              display: none !important;
            }
          }
        }

        .tasks__modal__search-input-btn.disabled {
          background-color: rgb(192, 192, 192) !important;
          cursor: not-allowed !important;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 2rem;
        z-index: 3;
        transform: translateY(-50%);
        background-image: url(../../../../public/search.svg);
        background-size: contain;
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
      }

      &--small {
        font-size: 12px;
        color: rgb(165, 163, 163);
        margin-left: 2rem;
        margin-top: 0.5rem;
        display: block;
      }

      input {
        border-radius: 50px !important;
        background: white !important;
        box-shadow: 0px 2px 6px rgba(7, 31, 57, 0.1);
        padding-left: 3rem;
        position: relative;
      }
    }

    &__column {
      max-width: 500px;
      width: 100%;
      margin-top: 5%;
      margin-right: 8rem;

      > * {
        margin-bottom: 3rem;
      }
      &--btn {
        margin-right: 0%;
      }
    }

    &__container {
      min-height: 650px;
      height: 70vh;
      width: 90vw;
      padding: 2.5rem 4rem;
      border-radius: 10px;
      background-color: $color_bcg;

      &--small {
        width: 60vw;
      }

      &--over {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;

        .modal__content__body {
          margin-top: 1rem;
        }

        .modal__content__footer {
          margin-top: 2rem;
          display: flex;
          column-gap: 1rem;
          align-items: center;
          justify-content: center;

          > * {
            margin-left: 0 !important;
            max-width: fit-content !important;
            span {
              max-width: fit-content !important;
            }
          }
        }
      }
    }

    &__content {
      &__subtitle {
        margin-top: 1rem;
      }
    }
  }

  &__cancel {
    margin-left: auto;

    &__button {
      transform: rotate(45deg);
    }
  }
}

// CUSTOM OVER MUI

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiToggleButton-root.Mui-selected {
  background-color: $color_bcg !important;
  color: $color_primary !important;
  border: 1px solid $color_blue_light !important;
  border-radius: 20px !important;
  height: 32px !important;
  width: 100% !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.MuiToggleButton-root {
  background-color: white !important;
  color: $color_primary !important;
  border: 1px solid $color_blue_light !important;
  border-radius: 20px !important;
  height: 32px !important;
  width: 100% !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  min-width: 110px;
  margin-right: 0.5rem !important;
  font-family: $font_main !important;
  transition: all 0.15s ease;

  &:hover {
    background-color: $color_blue_light_op !important;
    transition: all 0.15s ease;
  }
}

.MuiDataGrid-columnHeaderTitle {
  color: $color_primary !important;
  font-family: $font_main !important;
}

.MuiDataGrid-root
  .MuiDataGrid-root--densityStandard
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell,
.css-f3jnds-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid $color_border !important;
}

.MuiDataGrid-root
  .MuiDataGrid-root--densityStandard
  .MuiDataGrid-iconSeparator {
  g {
    fill: $color_blue_light !important;
  }
}
.toggleBtn--red {
  color: $color_er !important;
  border: 1px solid $color_er !important;
  background-color: $color_er_light !important;
  position: relative;
  padding-left: 1.15em !important;
  border-radius: 20px !important;
  height: 32px !important;
  width: 111px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  transition: all 0.15s ease;

  &::before {
    content: '';
    height: 15px;
    width: 15px;
    background-image: url('../../../../public/report.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0.51rem;
    left: 1.15em;
  }

  &:hover {
    transition: all 0.15s ease;
    background-color: $color_er_bcg !important;
    color: $color_white !important;

    &::before {
      filter: contrast(1) saturate(0) brightness(0) invert(1);
    }
  }
}

.toggleBtn--filtered {
  padding-right: 1rem !important;
  padding-left: 2.3rem !important;
  width: fit-content !important;
  margin-left: 1rem !important;
  margin-bottom: 1rem !important;
}

@include screen-size(xl) {
  @media (orientation: portrait) {
    .tasks {
      &__modal {
        &__container {
          width: 70vw;
        }
      }
    }
  }
}
