/* COLORS */

$color: #1e1e1e;
$color_primary: #054991;
$color_secondary: #5f9bc1;
$color_active: #053971;
$color_blue_light: #c2d2e3;
$color_blue_light_op: #c2d2e37c;
$color_card_hover: #05499130;
$color_placeholder: #274f84;

$color_action: #11946a;
$color_action_hover: #0d7a58;
$color_action_bcg: #dbfff2;
$color_action_active: #0c5c43;

$color_er: #dd2e02;
$color_er_bcg: #f34f2b;
$color_er_light: #ffe5df;
$color_er_active: #c12701;

$color_grey: #f6f7fa;
$color_white: #ffffff;
$color_bcg: #f1f4fc;
$color_border: #dee6ef;

/* FONTS */

$font_main: 'Trebuchet MS';

/* ANIMATIONS */

$cubic: cubic-bezier(0.19, 1, 0.22, 1);
$cubic-zoom: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$cubic-img: cubic-bezier(0.38, 0.005, 0.215, 1);

$time: 0s;
