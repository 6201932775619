.lds {
	display: inline-block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
}
.lds div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid lightsalmon;
	border-radius: 50%;
	animation: lds 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: lightsalmon transparent transparent transparent;
}
.lds div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
