.entry {

	&__title{
		font-size: 54px;
		display: flex;
		padding-left: 4rem;
		margin-bottom: 3rem;
		margin-top: 6.4vw;
	}
	&__container {
		max-width: 100%;
		padding: 4rem;
		padding-top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
	}

	&__card {
		height: 50vh;
		width: 100%;
		background-color: $color_bcg;
		text-align: center;
		padding: 2rem;
		border-radius: 10px;
		border: 1px solid rgba(5, 73, 145, 0.2);
		transition: all 0.2s ease-in;

		&__title {
			font-size: 48px;
			font-weight: 600;
			margin-top: 7vh;
			letter-spacing: -0.03em;
		}

		&__text {
			text-align: center;
			width: 100%;
			margin: auto;
			letter-spacing: -0.02em;
			line-height: 1.3;
			max-height: 200px;
		}

		&__link {
			position: relative;
			margin-top: 30%;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-image: url('../../../../public/add_btn.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 4rem;
				width: 4rem;
			}
		}

		&:hover, &:focus {
			background-color: $color_card_hover;
			cursor: pointer;

		}
		&:active{
			background-color: $color_blue_light;
			transition: none;
		}
	}
}


@include screen-size(xl) {
	.entry{
		&__title{
			font-size: 40px;
			padding-left: 2rem;
		}
		&__container{
			padding: 2rem;
		}
		&__card{
			&__title{
				font-size: 34px;
			}
		}
	}
 }


 @include screen-size(xxl){
	@media (orientation: portrait) {
		.entry{
			&__container{
				flex-direction: column;
			}
			&__card{
				height: 100%;
				width: 70vw;

				&__title{
					margin-top: 0;
				}
				&__text{
					max-width: 180px;
				}
				&__link{
					margin-top: 3rem;
					padding-bottom: 3rem;
				}
			}
		}
	  }
}

@include screen-size(lg){
	@media (orientation: portrait) {
		.entry{
			&__title{
				margin-bottom: 1rem;
			}

			&__container{
				flex-direction: column;
			}
			&__card{
				height: 100%;
				width: 70vw;

				&__title{
					margin-top: 0;
				}
				&__text{
					max-width: 200px;
				}
				&__link{
					margin-top: 2rem;
					padding-bottom: 3rem;
				}
			}
		}
	  }
}