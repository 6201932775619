.listing {
	overflow-y: visible;

	&__table {
		margin-top: 2rem;
		margin-bottom: 10rem;

		&__row {
			margin-top: 2rem;
		}

		&__data {
			padding: 1.6rem 10px;
			border-bottom: 1px solid $color_border;
		}
	}
	&__excel {
		cursor: pointer;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: -7px;
			right: -2.2rem;
			background-image: url(../../../../public/download.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 28px;
			height: 28px;
			transition: all 0.15s ease;
		}
		&:hover {
			&::before {
				background-image: url(../../../../public/download_hover.svg);
				transition: all 0.15s ease;
			}
		}
	}

	&__title {
		display: flex;
		align-items: flex-end;

		span {
			margin-left: 1rem;
			margin-bottom: 1rem;
		}
	}

	&__filters {
		padding-top: 7rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid $color_border;

		&__title {
			margin-bottom: 1.5rem;
		}

		&__container {
			display: flex;
			align-items: center;
			position: relative;
			width: 100%;
			flex-wrap: wrap;
			right: 0;
			display: flex;
			align-items: center;
			margin-top: 0.2rem;

			* {
				display: flex;
			}
		}

		&__input {
			border: 1px solid $color_border;
			border-radius: 20px;
			margin-right: 1rem;
			max-width: 150px;
			font-size: 14px;
			padding: 0.5rem 1.2rem !important;

			&--select {
				background-color: transparent !important;
				width: 200px !important;
				border-radius: 20px !important;
				padding: 0 !important;
				font-size: 14px !important;
				max-height: 36px !important;
			}

			&.MuiInputBase-root {
				background-color: transparent !important;
				border-radius: 5px;
				font-family: $font_main !important;
				color: $color_primary !important;
				box-shadow: none !important;
			}
		}

		&__submit {
			background-color: $color_primary !important;
			color: $color_white !important;
			padding: 0.5rem 1.2rem;
			border-radius: 20px;

			&:focus {
				color: $color_white;
			}
		}
	}

	&__header {
		text-align: left;
		border-bottom: 1px solid $color_border;

		&__title {
			padding: 20px 10px;
			font-weight: 500;
		}
	}

	&__cell {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		color: $color_primary !important;
		text-decoration: none !important;
		font-family: $font_main !important;
		display: flex;
		align-items: center;
		padding: 0.5rem !important;

		&--bcg {
			background-color: rgba(176, 223, 253, 0.273);
		}
	}
}
// CUSTOM CSS ON TABLE

.MuiDataGrid-toolbarContainer {
	display: none !important;
}

.MuiDataGrid-root {
	border: 1px solid $color_border !important;
}

.MuiDataGrid-cell {
	border-bottom: 1px solid $color_border !important;
	padding: 0 !important;
}

.MuiTablePagination-displayedRows {
	font-family: $font_main !important;
	color: $color_primary !important;
}

.MuiSelect-select .MuiSelect-filled {
	background-color: transparent !important;
	padding: 0.5rem 1.2rem !important;
}

@include screen-size(xl) {
	.listing {
		&__filters {
			&__input {
				max-width: 120px;
				width: 100%;
			}
			&__container {
				width: 100%;
				flex-wrap: nowrap;
			}
		}
	}
}

@include screen-size(lg) {
	.listing {
		&__filters {
			&__container {
				width: 75%;
				flex-wrap: wrap;
				justify-content: space-between;
				&--col2,
				&--col3 {
					margin-top: 1rem;
				}
				&--col1,
				&--col3 {
					margin-right: 3rem;
				}
			}

			&__input {
				max-width: 150px;
				width: 100%;
				&--submit {
					margin-top: 1rem;
				}
			}
		}
	}
}
