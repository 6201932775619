.single {
  padding-bottom: 15rem;

  &__state {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  &__header {
    display: flex;
    align-items: flex-end;

    &__link {
      margin-bottom: 1.4rem;
      margin-right: 1rem;
      font-size: 13px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0.1rem;
        left: -1.4rem;
        background-image: url(../../../../public/arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        height: 12px;
        width: 16px;
      }
    }

    &__title {
      text-transform: capitalize;
    }
  }

  &__footer {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(3.5px);
      padding: 1rem 2rem;
      width: fit-content;
      border-radius: 70px;
      width: fit-content;
      height: 66px;
      column-gap: 1rem;
      position: fixed;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      margin-left: 2rem;

      > * {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &__element {
      width: max-content;

      &--last {
        padding-left: 2rem;
        border-left: 1px solid #0000003f;
      }

      &--disabled {
        background: #a7a7a7 !important;
        pointer-events: none !important;
        border-radius: 20px !important;
        color: white !important;
        border: none !important;

        .incharge__text::before {
          background-image: url('../../../../public/incharge-white.svg') !important;
        }

        button {
          background: #a7a7a7 !important;
          pointer-events: none;
          border-radius: 20px !important;

          &:hover {
            opacity: 1 !important;
            cursor: not-allowed !important;
          }
        }
      }
    }
    &__btn {
      position: relative;
      z-index: 99;

      &--close {
        margin-top: 2rem;
      }

      &--open {
        margin-top: 6rem;
      }
    }
  }
}

@include screen-size(xl) {
  .single {
    &__footer {
      &__container {
        // flex-direction: column;

        // & > div {
        //   margin-bottom: 5rem;
        // }
      }
      &__btn {
        // margin-top: 2rem;

        &--open {
          margin-top: 2rem;
        }
      }
    }
  }
}

.form__submit--send {
  position: relative;
  padding-left: 3rem !important;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/send.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
  background-color: $color-primary !important;
}

.form__submit--close {
  position: relative;
  padding-left: 3rem !important;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
  background-color: $color-primary !important;
}

.form__submit--open {
  position: relative;
  padding-left: 3rem !important;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/open.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
  background-color: $color-primary !important;
}

.form__submit--archive {
  position: relative;
  padding-left: 3rem !important;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/archive.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
  background-color: $color-primary !important;
}

.single__footer__btn--update {
  button {
    max-width: 300px !important;
    background-color: $color_action !important;
  }
}

.single__footer__container {
  .margin-left {
    border-right: 1px solid #bebebe;
  }
}

.MuiDialog-root {
  .MuiDialog-scrollPaper {
    .MuiSelect-filled {
      min-width: 230px;
      background-color: #bebebe00 !important;
    }
  }

  .MuiDialog-paper {
    width: 25vw !important;
    height: 20vw !important;
    padding: 2rem !important;
    transform: scale(1.2) !important;
    background-color: white !important;

    > * {
      padding: 0 !important;
      margin-bottom: 1rem !important;
    }
  }

  .MuiButton-root {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .MuiFormControl-root {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

@include screen-size(xl) {
  .MuiDialog-root {
    .MuiDialog-paper {
      width: 30vw !important;
      height: 30vw !important;
    }
  }
}
