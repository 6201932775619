// @include font-face($font_main, $font_main);
body {
	font-family: $font_main, sans-serif;
	color: $color_primary;
}

h1,
h2 {
	font-size: 64px;
	line-height: 90px;
	line-height: 1.3;
}

h3 {
	font-size: 34px;
	font-weight: 500;
}

	h4 {
		font-weight: 500;
	}

small {
	font-size: 22px;
}
p {
	font-size: 17px;
}

a {
	font-size: 17px;
}


@include screen-size(xl) {
	h1,
	h2 {
		font-size: 40px;
		font-weight: 500;
	}
	h3 {
		font-size: 25px;
	}
	
	p {
		font-size: 16px;
	}
	
	a {
		font-size: 16px;
	}
}
