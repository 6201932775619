.add {
	position: absolute;
	left: 8.8vw;
	z-index: 4;

	&__btn {
		height: 5rem;
		width: 5rem;
		border: none;
		border-radius: 100%;
		position: relative;
		background-color: $color_action;
		transition: 0.2s ease-in-out;
		display: flex;
		align-items: center;
		color: $color_action;

		&::before {
			content: '';
			height: 2.5rem;
			width: 2.5rem;
			background-image: url('../../../../public/cross.svg');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all 0.2s ease-in;
		}

		&::after {
			content: '';
			height: 6.5rem;
			width: 6.5rem;
			position: absolute;
			top: -0.7rem;
			left: -0.7rem;
			z-index: -1;
			background-color: $color_bcg;
			border-radius: 100%;
		}

		&:hover,
		&:focus {
			background-color: $color_action_hover;
		}
		&:active {
			background-color: $color_action_active;
		}
	}

	.active {
		.add__btn {
			color: $color_primary;
			background-color: $color_primary;

			&::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:hover,
			&:focus {
				background-color: $color_active;
			}
			&:active {
				background-color: $color_placeholder;
			}
		}
	}

	&__text {
		margin-left: -4.75rem;
	}
}

@include screen-size(xl) {
	.add {
		&__btn {
			font-size: 14px;
		}
		&__text {
			margin-left: -4rem;
		}
	}
}

@include screen-size(xl) {
	@media (orientation: portrait) {
		.add {
			&__btn {
				font-size: 14px;
				margin-left: 15px;
			}
		}
	}

	@media (orientation: landscape) {
		.add {
			&__btn {
				margin-left: -5px;
			}
		}
	}
}
