// RESET MARGIN AND PADDING

@mixin spaceReset {
  margin: 0;
  padding: 0;
}

// CENTER ELEMENT QUICKLY

@mixin auto-center {
  margin: {
    left: auto;
    right: auto;
  }
}

// RESPONSIVE DESIGN

$breakpoints: (
  'xs': 25em,
  // 400px
  'sm': 34em,
  // 544px
  'md': 48em,
  // 768px
  'lg': 60em,
  // 960px
  'xl': 80em,
  // 1280px
  'xl-m': 88em,
  // 1280px
  'xxl': 90em // 1440px,,,,,,,
);

@mixin screen-size($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//LANDSCAPE VERSION in MOBILE & TABLET

// IMPORT FONTS

@mixin font-face($name, $file) {
  @font-face {
    font-family: '#{$name}';
    src: url('../../assets/fonts/#{$file}.woff') format('Web Open Font Format');
  }
}
