.alert-modal {
  background-color: $color_white;
  height: 75vh;
  width: 60vw;
  padding: 5rem 4rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: center;

  h3 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__p {
    margin-top: 2rem;
    text-align: center;
    font-size: 16px;
  }

  &__link {
    margin-bottom: 2rem;
    font-size: 21px !important;
    position: relative;

    &__text {
      color: $color_action;
      margin: 2rem 0;

      &--red {
        color: $color_primary;
        font-size: 17px;
        margin-top: 0.5rem;
        line-height: 1.3;
        max-width: 500px !important;
      }
    }
  }

  &__small {
    font-size: 14px;
    margin-top: 10%;
    width: fit-content;
    color: $color_primary;
    position: absolute;
    top: -2.7rem;
    right: 1.5rem;

    span {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 28px;
        background-image: url('../../../../public/close-it.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        top: -0.3rem;
        left: -2.5rem;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__last {
    margin-bottom: 1.5rem;
  }

  &__container {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    margin: 0 auto;
  }

  .form__submit {
    max-width: max-content !important;
    width: fit-content !important;
  }

  &__close-btn {
    background-color: white !important;
    background: white !important;
    border: 1px solid $color_primary !important;
    color: $color_primary !important;
    width: fit-content !important;
    max-width: max-content !important;

    &:hover {
      opacity: 0.5;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 18px;
      background-image: url('../../../../public/giveup.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      top: 0.5rem;
      left: -2.95rem;
      fill: $color_primary;
    }

    span {
      margin-left: 0.75rem;
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.time {
  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
      background-color: $color_white;
      height: fit-content;
      width: 30vw;
      padding: 2.5rem 4rem;
      border-radius: 10px;

      > * {
        margin-bottom: 3rem;
      }
    }

    &__content {
      display: flex;
    }
  }
}

@include screen-size(xl) {
  @media (orientation: portrait) {
    .time {
      &__modal {
        &__container {
          width: 50vw;
          height: 65vh;
        }
      }
    }
  }
}

.handle--double {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.link--external {
  font-size: 17px !important;
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../../../../public/external-link.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 0rem;
    left: -1.75rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 120%;
    height: 1.2px;
    background-color: $color_primary;
    bottom: -0.5rem;
    left: -1.8rem;
  }
}

.green {
  color: $color_action;
}

.form__submit--open {
  &::before {
    background-image: url('../../../../public/incharge-white.svg') !important;
  }

  span {
    &::before,
    &::after {
      display: none !important;
    }
  }
}
