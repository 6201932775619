.notification-box {
  height: fit-content;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  position: fixed;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.4;
  z-index: 1000;
  column-gap: 1rem;
  z-index: 99999999;

  &--error {
    color: #fb613f;
    background-color: #fbeae6;

    .notification-box__code {
      background-color: #fb613f;
    }
  }

  &--success {
    color: #117454;
    background-color: #dbfff2;

    .notification-box__code {
      background-color: #117454;
    }
  }

  &__code {
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
  }
}
