* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	overflow: hidden;
}

body {
	margin: 0;
	background-color: $color_bcg;
	padding: 1rem;
	padding-right: 0%;
}

main {
	background-color: $color_white;
	height: 110vh;
	width: 100%;
	border-radius: 15px 15px 0 0;
	overflow: scroll;
	grid-column: 2/-1;
	padding-top: 2rem;
	padding-left: 5rem;
	padding-right: 1rem;
	position: relative;
	z-index: 0;
}

.switch {
	display: flex;
	> * {
		position: relative;
		z-index: 999999;
		margin-left: 1rem;

		&:hover {
			cursor: pointer;
		}
	}
}

.SelectPlaceholder {
	opacity: 0.4 !important;
}

// CUSTOM MUI COMPONENTS

.MuiDataGrid-root
	.MuiDataGrid-root--densityStandard
	.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
	> .MuiDataGrid-cell,
.MuiDataGrid-cell--withRenderer {
	&:focus {
		outline: none !important;
	}
}

.margin-left {
	margin-left: 7px !important;
}

.MuiDataGrid-cell {
	font-family: $font_main;
	color: $color_primary;
}
.tasks__cell {
	padding-left: 0.5rem;
}

.MuiTypography-root {
	font-family: $font_main !important;
	color: $color_primary !important;
}

/* 

@include screen-size(xxl) {
	body{
		background-color: rgba(107, 128, 0, 0.316);
	}
}
@include screen-size(xl) {
	body{
		background-color: rgba(75, 0, 128, 0.316);
	}
}

@include screen-size(lg) {
	body{
		background-color: rgba(0, 128, 0, 0.316);
	}
} */

.circle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: grey;

	&--center {
		margin: auto;
	}

	&--green {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color_action;
	}
	&--red {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color_er;
	}
	&--yellow {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color_placeholder;
	}
}
