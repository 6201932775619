.link {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0.3rem;
		right: -1.4em;
		background-image: url(../../../../public/arrow.svg);
		background-size: contain;
		background-repeat: no-repeat;
		height: 12px;
		width: 16px;
		transform: rotate(180deg);
	}
}
