.lead {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &__title {
      margin-right: 1rem;
    }

    &__description {
      max-width: 274px;
      line-height: 1.2;
    }

    &__step {
      display: block;
      margin-bottom: 1rem;
    }
  }

  &__step {
    &__title {
      font-size: 32px;
      line-height: 35px;
      padding-bottom: 1rem;
      margin-top: 15vh;
      border-bottom: 1px solid $color_border;

      &--margin {
        margin-bottom: 4rem;
      }
    }
  }

  &__success {
    color: $color_action;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -8rem;
      left: 50%;
      background-image: url(../../../../public/done.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 120px;
      width: 120px;
      transform: translate(-50%, 0);
    }

    &--small {
      font-size: 14px;
      margin-top: 1rem;
    }
  }

  &__error {
    font-family: $font_main !important;
  }
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85%;
}

.form {
  &--entry {
    min-height: 1400px;
  }
  margin-top: 3rem;
  position: relative;

  &__next {
    position: absolute;

    &--entry {
      position: absolute;
      right: 0vw;
      display: flex;
      justify-content: flex-end;
      gap: 1em;
    }
    &--lead {
      top: 40vh;
    }
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;

    &--secondary {
      margin-top: 3rem;
    }

    &--third {
      align-items: flex-end;

      .form {
        &__next {
          grid-area: 1/3;
        }
      }
    }

    &--width {
      grid-template-columns: 1fr;

      .form__field {
        max-width: none !important;
      }
    }
  }

  &__field {
    width: 100%;
    max-width: 500px;

    > * {
      width: 100%;
    }

    input {
      background-color: $color_bcg;
      color: $color_primary !important;
      font-family: $font_main;

      &::placeholder {
        font-family: $font_main;
        color: $color_placeholder;
      }
    }

    &__title {
      margin-bottom: 1rem;
      margin-left: 0.2rem;
      font-weight: 600;
      background-color: white;
    }

    &__date {
      display: none;

      &--display {
        display: block;
      }
    }
    &--large div {
      padding-bottom: 5vh;
    }
  }
}

// Custom MUI Component

.MuiFilledInput-root {
  background-color: transparent !important;

  &::after,
  &::before {
    display: none !important;
  }
}

.MuiFilledInput-input {
  padding: 1.2rem 1.5rem !important;
  border-radius: 10px;
}

.MuiButton-text {
  background-color: $color_primary !important;
  text-transform: none !important;
  font-family: $font_main !important;
  padding: 0.4rem 2rem !important;
  border-radius: 20px !important;
  max-width: 150px;
  max-height: 37px;
  margin-left: auto !important;
}

.MuiButton-text {
  color: $color_white !important;
}

.form {
  &__previous {
    background-color: $color_bcg !important;
    border: 1px solid $color_primary !important;

    span {
      color: $color_primary !important;
    }
  }

  &__submit {
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 0.5;
    }

    &--width-fit {
      width: 100%;
      max-width: fit-content;
    }

    &--archive {
      background-color: #686868 !important;
    }
    &--close {
      background-color: #fb613f !important;
    }
    &--send {
      background-color: #9bb6d3 !important;
    }
    &--open {
      background-color: #117354 !important;
    }
  }
}

.MuiPaper-root {
  background-color: $color_bcg !important;
  border-radius: 10px;
  font-family: $font_main !important;
  color: $color_primary !important;
  box-shadow: none !important;
}

.MuiList-root {
  margin-top: 1rem;
  border: 1px solid $color_border !important;
  border-radius: 10px !important;
  padding-top: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.MuiMenuItem-root {
  background-color: $color_bcg !important;
  border-radius: 10px;
  font-family: $font_main !important;
  color: $color_primary !important;
  border-bottom: 1px solid $color_border !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  border-radius: 10px !important;

  &:hover {
    background-color: $color_primary !important;
    color: $color_white !important;
  }
}

.MuiInputBase-root {
  background-color: $color_bcg !important;
  border-radius: 5px;
  font-family: $font_main !important;
  color: $color_primary !important;
  box-shadow: none !important;
}

.menuitem--last {
  border-bottom: none !important;
}

.MuiSelect-select .MuiSelect-filled .MuiInputBase-input .MuiFilledInput-input {
  background-color: transparent !important;
}
