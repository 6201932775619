.logincard {
	height: 100%;
	width: 100%;
	border-radius: 10px;
	border: 1px solid $color_blue_light;
	display: none;
	padding: 0 2rem;
	background-color: $color_white;
	padding-top: 2rem;
	justify-content: space-between;

	&__title {
		font-size: 23px;
		margin-bottom: 12px;
	}
	

	&__p {
		font-size: 10px;
		padding: 0.5rem 1rem;
		border-radius: 13px;
		color: $color_placeholder;
		border: 1px solid $color_placeholder;
		max-width: 67px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
