.incharge {
  height: 28px;
  width: 80px;
  background-color: $color_action_bcg;
  color: $color_action;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  margin-right: 1em;
  border: solid 1px $color_action;
  transition: background 0.15s ease;
  font-family: $font_main;

  &__text {
    position: relative;

    &::before {
      content: '';
      height: 14px;
      width: 14px;
      background-image: url('../../../../public/incharge.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0.1px;
      left: -17px;
    }
  }

  &--single {
    height: 2.2rem;
    width: 7rem;
    font-size: 0.875rem;

    &::before {
      height: 16px;
      width: 16px;
      top: 0.3px;
    }
  }

  &:hover {
    background-color: $color_action_hover;
    color: $color_action_bcg;
    transition: background 0.15s ease;

    * {
      filter: contrast(1) saturate(0) brightness(0) invert(1);
    }
  }
}

.handle--single {
  border: none;

  &:hover {
    background-color: $color_action_bcg;
    color: $color_action;
    cursor: auto;

    * {
      filter: none;
    }
  }
}
.report {
  height: 28px;
  width: 80px;
  background-color: $color_er_light;
  color: $color_er;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  cursor: default;
  border: solid 1px $color_er;
  transition: background 0.15s ease;
  font-family: $font_main;

  &:hover {
    background-color: $color_er_bcg;
    color: $color_er_light;
    transition: background 0.15s ease;
    cursor: pointer;

    * {
      filter: contrast(1) saturate(0) brightness(0) invert(1);
    }
  }
}

.report--single {
  border: none;

  &:hover {
    background-color: $color_er_light;
    color: $color_er;
    cursor: auto;

    * {
      filter: none;
    }
  }
}

.locked {
  height: 28px;
  background-color: $color_grey;
  color: $color_placeholder;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 26px;
  padding-right: 12px;
  cursor: default;
  border: solid 1px $color_border;
  transition: background 0.15s ease;
  font-family: $font_main;

  &__text {
    position: relative;
    opacity: 0.5;

    &::before {
      content: '';
      height: 14px;
      width: 14px;
      background-image: url('../../../../public/report.svg');
      filter: invert(1) brightness(0.2);
      opacity: 0.5;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -17px;
    }
  }
}

.date {
  height: 28px;
  width: 80px;
  background-color: $color_blue_light_op;
  color: $color_primary;
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  border: solid 1px $color_primary;
  transition: background 0.15s ease;
  font-family: $font_main;

  &__text {
    position: relative;

    &::before {
      content: '';
      height: 14px;
      width: 14px;
      background-image: url('../../../../public/date.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0.1px;
      left: -17px;
    }
  }

  &:hover {
    background-color: $color_placeholder;
    color: $color_action_bcg;
    transition: background 0.15s ease;

    * {
      filter: contrast(1) saturate(0) brightness(0) invert(1);
    }
  }
}
