.widget {
	max-height: 225px;
	height: 100%;
	width: 100%;
	max-width: 350px;
	border-radius: 10px;
	border: 1px solid #cbd9e7;
	background-color: $color_bcg;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 8rem 2rem;
	transition: all 0.2s ease-in-out;

	&:hover,
	&:focus {
		background-color: $color_card_hover;
		cursor: pointer;
	}
	&:active {
		transition: none;
		background-color: $color_blue_light;
	}

	> * {
		margin: 1rem 0;
	}

	&__title {
		display: flex;
		margin-bottom: 19px;
		padding-left: 2rem;

		&--red {
			position: relative;
			padding-left: 30px;
			color: $color_white;
			background-color: $color_er_bcg;

			&::before {
				content: '';
				height: 20px;
				width: 100%;
				position: absolute;
				top: 42px;
				left: 34px;
				background-image: url('../../../../public/logo_1.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}

			&:hover,
			&:focus {
				background-color: $color_er;
			}
			&:active {
				background-color: $color_er_active;
			}

			.link {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					top: 0.3rem;
					right: -1.4em;
					background-image: url(../../../../public/arrow-red.svg);
					background-size: contain;
					background-repeat: no-repeat;
					height: 12px;
					width: 16px;
					transform: rotate(180deg);
				}
			}
		}
		&--leads {
			position: relative;
			padding-left: 30px;
			&::before {
				content: '';
				height: 20px;
				width: 100%;
				position: absolute;
				top: 42px;
				left: 34px;
				background-image: url('../../../../public/logo_2.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		&--complaints {
			position: relative;
			padding-left: 30px;
			&::before {
				content: '';
				height: 20px;
				width: 100%;
				position: absolute;
				top: 42px;
				left: 34px;
				background-image: url('../../../../public/logo_3.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		&--questions {
			position: relative;
			padding-left: 30px;
			&::before {
				content: '';
				height: 20px;
				width: 100%;
				position: absolute;
				top: 42px;
				left: 34px;
				background-image: url('../../../../public/logo_4.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}

	&__infos {
		margin-bottom: 3rem;
		display: flex;
		align-items: flex-end;

		&__num {
			font-size: 63px;
			font-weight: 800;
		}

		&__text {
			font-size: 17px;
			margin-left: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	&__list {
		display: flex;
		column-gap: 0.5rem;
		margin-top: 2rem;
	}
}

@include screen-size(xxl) {
	@media (orientation: portrait) {
		.widget__list {
			margin-top: 1rem;
			display: grid;
			grid-template-columns: repeat(2, auto);
			grid-template-rows: repeat(2, auto);
			gap: 1rem;
			margin-top: 1rem;
			width: 100%;
		}
		.widget {
			height: 100px;
			padding: 7rem 2rem;

			&__title {
				&--red {
					&::before {
						top: 28px;
					}
				}
				&--leads {
					&::before {
						top: 28px;
					}
				}
				&--complaints {
					&::before {
						top: 28px;
					}
				}
				&--questions {
					&::before {
						top: 28px;
					}
				}
			}
		}
	}
}
