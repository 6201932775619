.wrapper {
	padding: 0 3.2vw;
	margin-right: auto;
	margin-left: auto;
	display: grid;
	grid-template-columns: repeat(10, 1fr);

	&--small {
		max-width: 770px;
		margin-right: auto;
		margin-left: auto;
		padding: 0 6.4vw;
	}
}

@media only screen and (min-width: 600px) {
	.wrapper {
		padding: 0 0.5vw;

		&--small {
			max-width: 770px;
			margin-right: auto;
			margin-left: auto;
			padding: 0 3.2vw;
		}
	}
}
